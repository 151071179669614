import app from 'flarum/app';
import { extend } from 'flarum/extend';

// 1. Import Page component
// extracted from routes.js
import IndexPage from 'flarum/components/IndexPage';
import DiscussionPage from 'flarum/components/DiscussionPage';
import PostsUserPage from 'flarum/components/PostsUserPage';
import DiscussionsUserPage from 'flarum/components/DiscussionsUserPage';
import TagsPage from 'flarum/tags/components/TagsPage';

app.initializers.add('springboardvr/flarum-redirect-unauthenticated', function(app) {

    let redirectUserToLogin = function(view) {
        // Check if there is no logged-in user
        if (!app.session.user) {

            let loginUrl = app.forum.data.attributes['wuethrich44-sso.login_url'];

            // if login URL is not set, use this by default
            if(!loginUrl || loginUrl.length < 4) {
                loginUrl = "https://app.springboardvr.com/sso/community";
            }

            // Empty the vdom children
            view.children = [];

            // Redirect User to login page
            view.children.unshift(
                window.location.replace(loginUrl)
            );
        }
    };

    let toExtend = [
        DiscussionPage,
        PostsUserPage,
        DiscussionsUserPage,
        IndexPage,
        TagsPage
    ];

    // We now extend the view of the component.prototype
    toExtend.forEach(function (component) {
        extend(component.prototype, 'view', function(view) {
            redirectUserToLogin(view);
        });
    });

}, 200);